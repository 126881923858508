

































































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import { Global, User, Groups, Schedules, Props } from '@/store';
import moment from 'moment';
import ConfirmDialog from '@/components/ConfirmDialog.vue';
import DatePicker from './components/CustomDatePicker.js';
import API, { Types } from '@/modules/API';
import PropEditor from '@/components/PropEditor.vue';
import GroupsDialog from './GroupsDialog.vue';
import InfoDialog from './InfoDialog.vue';
import ChartPreview from './ChartPreview.vue';

// @ts-ignore
@Component({
  components: {
    ConfirmDialog,
    DatePicker,
    PropEditor,
    GroupsDialog,
    InfoDialog,
    ChartPreview
  }
})

export default class Schedule extends Vue {
  @Global.State('readonly_user') readonly_user;
  @Global.State('lang') lang;
  @User.State('project') project;
  @Groups.Action('load') fetchGroups;
  @Groups.State('list') groupList;
  @Schedules.State('userCurves') userCurves;
  @Schedules.Action('deleteCurve') deleteCurve;
  @Schedules.Action('loadUserCurves') loadUserCurves;
  @Schedules.Action('loadSchedules') loadSchedules;
  @Schedules.Action('updateExistSchedule') updateSchedule;
  @Schedules.State('schedules') schedules;
  @Schedules.Action('runSchedulerInServer') runSchedulerInServer;

  monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ];

    monthNamesHe = [
      'ינואר',
      'פברואר',
      'מרץ',
      'אפריל',
      'מאי',
      'יוני',
      'יולי',
      'אוגוסט', 
      'ספטמבר',
      'אוקטובר',
      'נובמבר',
      'דצמבר'
  ];

  weekDays = [
    {
      title: 'Sunday',
      titleHe: 'א',
      value: 0
    },
    {
      title: 'Monday',
      titleHe: 'ב׳',
      value: 1
    },
    {
      title: 'Tuesday',
      titleHe: 'ג׳',
      value: 2
    },
    {
      title: 'Wednesday',
      titleHe: 'ד׳',
      value: 3
    },
    {
      title: 'Thursday',
      titleHe: 'ה׳',
      value: 4
    },
    {
      title: 'Friday',
      titleHe: 'ו׳',
      value: 5
    },
    {
      title: 'Saturday',
      titleHe: 'ש׳',
      value: 6
    }
  ];

  preview = null;

  scheduleGroup = false;

  isLoading = false;

  scheduleId = '';

  scheduleDay = [];
  months = [];
  deleteDialog = false;
  activeCurve = {
    color: '',
    id: ''
  };
  currentCurve = null;

  hover = null;

  loading = true;

  schedule = {
    groups: [],
    name: '',
    description: '',
    curves: [],
    id:
      '_' +
      Math.random()
        .toString(36)
        .substr(2, 9),
    active: 1
  };

  setPreview(preview) {
    this.preview = preview;
  }

  countDeletedDates(date, index) {
    const i = this.schedule.curves.map((v) => v.date).indexOf(date);
    if (i !== -1) {
      if (this.schedule.curves[i].id === this.activeCurve.id) {
        this.schedule.curves.splice(i, 1);
        const ind = this.months[index].indexOf(date);
        this.months[index].splice(ind, 1);
      } else {
        this.schedule.curves[i].id = this.activeCurve.id;
        this.schedule.curves[i].color = this.activeCurve.color;
      }
    } else {
      this.schedule.curves.push({
        id: this.activeCurve.id,
        color: this.activeCurve.color,
        date
      });
      this.months[index].push(date);
    }
  }

  handleWeekDays(day) {
    const manage = (type = 'del') => {
      const start = moment(this.months[0][0]);
      const end = moment(this.months[2][0]);
      const selectedDays = this.pickDayFromMonth(
        start,
        end.add(1, 'M'),
        day.value
      );
      this.months.forEach((v, index) => {
        selectedDays.forEach((val) => {
          if (val.includes(v[0])) {
            const ind = v.indexOf(val);
            if (type === 'del') {
              if (ind !== -1) {
                const i = this.schedule.curves.map((c) => c.date).indexOf(val);
                this.schedule.curves.splice(i, 1);
                v.splice(ind, 1);
              }
            }
            if (type === 'add') {
              if (ind === -1) {
                this.schedule.curves.push({
                  id: this.activeCurve.id,
                  color: this.activeCurve.color,
                  date: val
                });
                v.push(val);
              } else {
                const i = this.schedule.curves.map((v) => v.date).indexOf(val);
                this.schedule.curves[i] = {
                  id: this.activeCurve.id,
                  color: this.activeCurve.color,
                  date: val
                };
              }
            }
          }
        });
      });
    };
    const scheduleDay = this.scheduleDay;
    const index = scheduleDay.indexOf(day.value);
    if (index !== -1) {
      scheduleDay.splice(index, 1);
      manage('del');
    } else {
      scheduleDay.push(day.value);
      manage('add');
    }
  }

  pickDayFromMonth(start, end, dayNumber) {
    const arr = [];
    const tmp = start.clone().day(dayNumber);
    arr.push(tmp.format('YYYY-MM-DD'));
    while (tmp.isBefore(end)) {
      tmp.add(7, 'days');
      arr.push(tmp.format('YYYY-MM-DD'));
    }
    return arr;
  }

  get headers() {
    return [
      { text: this.$t('Color'), value: 'color' },
      { text: this.$t('Name'), value: 'name' },
      { text: this.$t('Edit'), value: 'edit' }
    ];
  }

  setMonths() {
    const months = [];
    for (let i = 0; i < 3; i++) {
      months.push([
        moment()
          .add(i, 'M')
          .toISOString()
          .substr(0, 7)
      ]);
    }
    this.months = months;
  }

  mounted() {
    try {
      if (document.getElementById('scheduleView')) {
        document.getElementById('scheduleView').scrollIntoView();
      }
      if (this.$route.path === '/schedules/create') {
        // @ts-ignore
        this.$refs.GroupsDialog.open();
      }
      this.scheduleId = this.$route.params.id;
      this.setMonths();
      this.loadData();   
    } catch (error) {
      console.log('error in schedules mounted', error);
    }
  }

  @Watch('project')
  async loadData() {
    if (this.project){
      this.loading = true;
      await this.fetchGroups({ project_id: this.project.id });
      await this.loadSchedules({ projectId: this.project.id });
      await this.loadUserCurves();
      this.setSchedule();
      this.loading = false;
    }
  }

  setSchedule() {
    if (this.scheduleId) {
      const schedule = this.schedules.find((val) => val.id === this.scheduleId);
      if (!schedule) return;
      this.schedule = schedule;
      if (schedule.curves) {
        if (schedule.curves.length !== 0) {
          this.activeCurve = schedule.curves[0];
        }
        const array = schedule.curves.map((v) => v.date);
        this.months.forEach((arr, i) => {
          array.forEach((v) => {
            if (v.includes(arr[0])) {
              arr.push(v);
            }
          });
        });
      }
    } else {
      if (this.userCurves.length !== 0) {
        this.activeCurve = this.userCurves[0];
      }
    }
  }

  getDateColor(date) {
    const curve = this.schedule.curves.find((v) => v.date === date);
    if (curve) {
      return curve.color;
    }
  }

  async saveNewSchedule() {
    if (this.schedule.groups.length < 1) {
      this.$store.dispatch('Global/throwNotify', {
        type: 'error',
        title: 'Error!',
        text: `There must be at least 1 group in the schedule.`
      });
      return;
    }
    if (!this.schedule['priority']) {
      if (this.schedules.length === 0) {
        this.schedule['priority'] = 1;  
        await this.validateProjectHasSchedule();
      } else {
        this.schedule['priority'] =
          Math.max.apply(
            Math,
            this.schedules
              .filter((v) => Number.isInteger(v.priority))
              .map((v) => Number(v.priority))
          ) + 1;
        if (this.schedule['priority'] === Infinity || this.schedule['priority'] === -Infinity) {
          this.schedule['priority'] = 1;
        }
      }
    }
    this.updateSchedule(this.schedule);

    (this.$refs.propEditor as PropEditor).save(
      this.schedules,
      {
        name: 'dashboard.schedules',
        objectID: this.project.id,
        objectType: Types.PROJECTS
      },
      () => {
        this.$router.push('/schedules');
      }
    );

    this.runSchedulerInServer();
  }

  async validateProjectHasSchedule(){
    const url = 'scheduler/update';
    const body = {
      project_id: this.project.id,
      company_id: this.project.company
    };

    try {
      const response = await API.dashboardAPI(url, 'POST', {}, body);
      if (response.status !== 200) console.log(response);
    } catch (e) {
      console.log(e);
    }
  }

  openDeleteModal(curve) {
    this.currentCurve = curve;
    this.deleteDialog = true;
  }

  async deleteCurveSchedule() {
    await this.deleteCurve(this.currentCurve.id);
    (this.$refs.propEditor as PropEditor).save(this.userCurves, {
      name: 'dashboard.curves',
      objectID: this.project.id,
      objectType: Types.PROJECTS
    }, () => { 
      this.runSchedulerInServer();
    });
    this.deleteDialog = false;
  }

  dateFormate(date) {
    if (!date) {
      return;
    }
    const [year, month, day] = date.split('-');
    this.monthNames = this.lang === 'he' ? this.monthNamesHe : this.monthNames;
    return `${this.monthNames[Number(month) - 1]} ${year}`;
  }

  prevHalfYear() {
    const updatedMonths = [];
    this.months.forEach((month, i) => {
      const arr = [];
      arr.push(
        moment(this.months[i][0])
          .subtract(2, 'M')
          .toISOString()
          .substr(0, 7)
      );
      updatedMonths[i] = arr.slice();
    });
    this.months = updatedMonths;
    this.setSchedule();
  }

  nextHalfYear() {
    const updatedMonths = [];
    this.months.forEach((month, i) => {
      const arr = [];
      arr.push(
        moment(this.months[i][0])
          .add(4, 'M')
          .toISOString()
          .substr(0, 7)
      );
      updatedMonths[i] = arr.slice();
    });
    this.months = updatedMonths;
    this.setSchedule();
  }

  setActiveRow(row) {
    this.activeCurve = row.item;
    const months = [];
    for (let i = 0; i < 6; i++) {
      months.push([
        moment()
          .add(i, 'M')
          .toISOString()
          .substr(0, 7)
      ]);
    }
  }

  // showDeleteCurve(curve) {
  //   this.deleteDialog = true;
  //   this.promptCurve = curve;
  // }

  handleChange() {
    // @ts-ignore
    this.$refs.GroupsDialog.handleChange();
  }

  editGroups() {
    // @ts-ignore
    this.$refs.GroupsDialog.handleChange();
    // @ts-ignore
    this.$refs.GroupsDialog.open();
  }
}
